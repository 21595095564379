const COMPANY_NAME_MAPPING: Record<string, string> = {
  'Leep Utilities': 'Leep Utilities',
  'Portsmouth Water Ltd': 'Portsmouth Water',
  'Severn Trent PLC': 'Severn Trent',
  NautilusGB: 'Nautilus',
};

export const formatCompanyName = () => {
  const companyName = process.env.NEXT_PUBLIC_COMPANY_NAME as string;
  return COMPANY_NAME_MAPPING[companyName] || companyName;
};
