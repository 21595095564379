import { useKrakenMutation } from '@/components/GraphQLClient';
import { MutationObtainKrakenTokenArgs } from '@/types/generated/graphql/schema';

import { obtainKrakenTokenSchema } from '../validations';

import ObtainKrakenToken from './ObtainKrakenToken.graphql';

export const useObtainKrakenToken = () => {
  return useKrakenMutation({
    mutation: (_: MutationObtainKrakenTokenArgs) => ObtainKrakenToken,
    validateFn(response) {
      return obtainKrakenTokenSchema.validate(response);
    },
  });
};
