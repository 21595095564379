import { useAccountNumberList } from '@/hooks/accounts/useAccountNumberList';

export const useIsPortfolioAccount = (shouldFetch = true) => {
  const { data, isError, isSuccess, isLoading } =
    useAccountNumberList(shouldFetch);

  return {
    isPortfolioAccount: isSuccess && data && data.length > 1,
    isError,
    isLoading,
  };
};
