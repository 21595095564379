import { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import {
  Card,
  Container,
  Grid,
  Link,
  Stack,
  Typography,
} from '@krakentech/coral';
import { deleteCookie } from 'cookies-next';
import { Form, Formik, FormikHelpers } from 'formik';
import * as yup from 'yup';

import {
  Alert,
  ErrorLink,
  FormContainer,
  FormPasswordField,
  FormSubmitButton,
  LoginActionCard,
} from '@/components';
import PIIFormikTextField from '@/components/PII/PIIFormikTextField';
import { useAuth } from '@/components/Providers/Auth';
import { useIsPortfolioAccount } from '@/hooks/accounts/useIsPortfolioAccount';
import { useObtainKrakenToken } from '@/hooks/auth/useObtainKrakenToken';
import { useFeatureFlag } from '@/hooks/utils/useFeatureFlags';
import { FeatureNames } from '@/types/features';
import { COOKIES } from '@/utils/cookies';
import { isStw } from '@/utils/environment';
import { formatCompanyName } from '@/utils/formatters/companyName';
import { INTERNAL_PATHS } from '@/utils/urls';

import CustomHead from './_head.page';

const invalidFormFieldMessage = 'Invalid, please check';
const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email(invalidFormFieldMessage)
    .required(invalidFormFieldMessage),
  password: yup.string().required(invalidFormFieldMessage),
});

const Login = () => {
  const { login, logout, isAuthenticated } = useAuth();
  const { push } = useRouter();

  const { mutate, isLoading, isError } = useObtainKrakenToken();
  const showForgottenEmailLink = useFeatureFlag(FeatureNames.ForgottenEmail);
  const showRegisterForOnlineAccountCard = useFeatureFlag(
    FeatureNames.RegisterForOnlineAccount
  );
  const showCreateNewAccountCard = useFeatureFlag(
    FeatureNames.CreateNewAccount
  );

  const { SNOOZE_ACCOUNT_ENRICHMENT, SNOOZE_PSR_UPDATE } = COOKIES;

  const { isPortfolioAccount, isLoading: isPortfolioLoading } =
    useIsPortfolioAccount(isAuthenticated);

  const [shouldShowPortfolioNotice, setShouldShowPortfolioNotice] =
    useState(false);

  const redirectToDashboard = useCallback(() => {
    deleteCookie(SNOOZE_ACCOUNT_ENRICHMENT);
    deleteCookie(SNOOZE_PSR_UPDATE);
    void push(INTERNAL_PATHS.HOME.path);
  }, [SNOOZE_ACCOUNT_ENRICHMENT, SNOOZE_PSR_UPDATE, push]);

  useEffect(() => {
    if (isAuthenticated && isPortfolioAccount) {
      logout();
      setShouldShowPortfolioNotice(true);
    } else if (isAuthenticated && !isPortfolioLoading) {
      redirectToDashboard();
    }
  }, [
    logout,
    redirectToDashboard,
    isPortfolioAccount,
    isAuthenticated,
    isPortfolioLoading,
  ]);

  const handleSubmit = (
    values: { email: string; password: string },
    { resetForm }: FormikHelpers<{ email: string; password: string }>
  ) => {
    setShouldShowPortfolioNotice(false);
    mutate(
      {
        input: {
          email: values.email,
          password: values.password,
        },
      },
      {
        onSuccess: (data) => {
          const {
            obtainKrakenToken: { token: accessToken, refreshToken },
          } = data;

          login({
            accessToken,
            refreshToken,
          });
        },
        onError: () => {
          resetForm({
            values: {
              email: values.email,
              password: '',
            },
          });
        },
      }
    );
  };

  const largeGridTemplateColumns =
    showRegisterForOnlineAccountCard || showCreateNewAccountCard
      ? 'repeat(2, 1fr)'
      : 'repeat(1, 1fr)';

  return (
    <Container
      component="section"
      marginBottom="lg"
      marginX="auto"
      padding="md"
      maxWidth="lg"
      md={{
        padding: 'lg',
      }}
    >
      <CustomHead title={INTERNAL_PATHS.LOGIN.title} />
      <Stack direction="vertical">
        <Grid
          templateColumns={'repeat(1, 1fr)'}
          gap="md"
          md={{
            gap: 'lg',
          }}
          lg={{
            templateColumns: largeGridTemplateColumns,
          }}
        >
          <Stack.Item>
            <FormContainer>
              <Card>
                <Formik
                  initialValues={{
                    email: '',
                    password: '',
                  }}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  <Form>
                    <Stack direction="vertical" gap="md">
                      <Stack direction="vertical" gap="xl">
                        <Stack justifyContent="center">
                          <Typography variant="h1" textAlign="center">
                            Log in to your account
                          </Typography>
                        </Stack>
                      </Stack>
                      {isStw() && (
                        <Alert severity="info" removeIcon={true}>
                          <Typography>
                            Online accounts are currently only available to
                            customers who have been moved across to our new
                            system. Those who have been moved will have received
                            an email with instructions on setting up your online
                            account.
                          </Typography>
                        </Alert>
                      )}
                      <Stack gap="sm" direction="vertical">
                        <Stack gap="xs" direction="vertical">
                          <PIIFormikTextField
                            id="email"
                            name="email"
                            label="Email Address"
                          />
                          {showForgottenEmailLink && (
                            <Typography>
                              <Link
                                href={
                                  INTERNAL_PATHS.FORGOTTEN_EMAIL_YOUR_ACCOUNT
                                    .path
                                }
                                color="dark"
                              >
                                <span>Forgot my email address</span>
                              </Link>
                            </Typography>
                          )}
                        </Stack>
                        <Stack gap="xs" direction="vertical">
                          <FormPasswordField
                            id="password"
                            name="password"
                            label="Password"
                          />

                          {isError && (
                            <Alert severity="error">
                              We cannot sign you in - either your email or
                              password is incorrect
                            </Alert>
                          )}
                          {shouldShowPortfolioNotice && (
                            <Alert severity="error">
                              <Typography>
                                Sorry, your account is not supported online
                                right now - please{' '}
                                <ErrorLink
                                  href={process.env.NEXT_PUBLIC_HELP_LINK || ''}
                                >
                                  get in touch
                                </ErrorLink>{' '}
                                and our team will help you
                              </Typography>
                            </Alert>
                          )}
                          <Typography>
                            <Link
                              href={INTERNAL_PATHS.FORGOTTEN_PASSWORD.path}
                              color="dark"
                            >
                              <span>Forgot my password</span>
                            </Link>
                          </Typography>
                        </Stack>
                      </Stack>
                      <FormSubmitButton loading={isLoading}>
                        Log in
                      </FormSubmitButton>
                    </Stack>
                  </Form>
                </Formik>
              </Card>
            </FormContainer>
          </Stack.Item>
          <Stack
            direction="vertical"
            gap="md"
            md={{
              gap: 'lg',
            }}
          >
            {showRegisterForOnlineAccountCard && (
              <Stack.Item>
                <FormContainer>
                  <LoginActionCard
                    header={`Already a ${formatCompanyName()}
                                customer?`}
                    buttonText="Register for an online account"
                    buttonHref={
                      INTERNAL_PATHS.REGISTER_FOR_ONLINE_ACCOUNT_YOUR_ACCOUNT
                        .path
                    }
                  />
                </FormContainer>
              </Stack.Item>
            )}
            {showCreateNewAccountCard && (
              <Stack.Item>
                <FormContainer>
                  <LoginActionCard
                    header={`Just moved into a home ${formatCompanyName()} supplies?`}
                    buttonText="Create a new account"
                    buttonHref={INTERNAL_PATHS.CREATE_NEW_ACCOUNT[0].path}
                  />
                </FormContainer>
              </Stack.Item>
            )}
          </Stack>
        </Grid>
      </Stack>
    </Container>
  );
};

export default Login;
